import { Flex } from "@chakra-ui/react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";
import React from 'react';

export default function LandingLayout(props) {
  return (
    
      <Flex 
        direction="column"
        align="center"
        maxW={{ xxl: "1200px" }}
        m="0 auto"
        
        {...props}
      >
        <Header />
        {props.children}
        <Footer />
      </Flex>

  );
}