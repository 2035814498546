import React from 'react'
import { VStack, Spacer, Text, Image, Button, HStack, Stack } from '@chakra-ui/react'

const FeatureCard = ({
  buttonText,
  buttonIcon,
  title,
  subtitle,
  imageSrc,
  imageAlt,
  bgImage,
  imagePosition,
  wideCard,
  ...rest
}) => {
  return (
    <VStack
      id='feature-card'
      border='1px solid #F2ECFB'
      borderRadius={'16px'}
      bgImage={bgImage}
      bgRepeat='no-repeat'
      bgPosition='center'
      bgSize='cover'
      mt={wideCard ? 5 : 0}
      w={wideCard ? { base: 'full', lg: '90%', xl: 'full' } : 'full'}
      maxW={'1280px'}
    >
      <Stack
        direction={wideCard ? { base: 'column', md: 'row' } : 'column'}
        align={'flex-start'}
        pl={{ base: 5, md: 10, lg: 5, xl: 10 }}
        w={'full'}
        pt={{ base: 5, md: 10, lg: 5, xl: 10 }}
      >
        <VStack align={'flex-start'} pb={wideCard ? { base: 5, md: 10 } : 0}>
          <Button
            p='2'
            size='xs'
            color='#7845DB'
            backgroundColor='#F2ECFB'
            border='1px solid #D5C5F4'
            leftIcon={buttonIcon}
          >
            {buttonText}
          </Button>
          <Text
            fontSize='4xl'
            fontWeight='600'
            lineHeight='44px'
            color='#422678'
            textAlign={'left'}
            py={3}
            maxW={'480px'}
          >
            {title}
          </Text>
          <Text fontSize='md' textAlign='left' lineHeight='24px' color='#6C727E' maxW={'456px'}>
            {subtitle}
          </Text>
        </VStack>
        <Spacer />
        <HStack w='100%' justifyContent={imagePosition}>
          <Image
            src={imageSrc}
            alt={imageAlt}
            w={wideCard ? { base: '100%', md: 'auto' } : { base: '80%', md: 'auto' }}
            {...rest}
          />
        </HStack>
      </Stack>
    </VStack>
  )
}

export default FeatureCard
