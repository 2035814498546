import { selectAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    padding: 4,
    fontSize: 'sm',
    height: 'auto',
    lineHeight: 'auto'
  },
})

export const selectTheme = defineMultiStyleConfig({
  baseStyle
})