import {
  GoogleLogo,
  MetaLogo,
  LinkedInLogo,
  SnapchatLogo,
  TikTokLogo,
  ElipseDot,
  GoogleIcon,
  GoogleIconGray,
  MetaIcon,
  MetaIconGray,
  LinkedInIcon,
  LinkedInIconGray,
  SnapchatIcon,
  SnapchatIconGray,
  GoogleDisplay,
  GoogleSearch,
  LinkedinCarousel,
  LinkedinSingle,
  MetaCarousel,
  MetaSingle,
  SnapchatCollection,
  SnapchatSingle,
  SnapchatStory,
  TikTok,
  PerformanceMax,
  InstantExperience,
  DemandGen,
} from '../sections/index.js'

export const buttons = [
  {
    name: 'Google',
    icon: GoogleLogo,
    text: 'Text-based or visual ads that appear in search results or on websites across the Google Display Network, targeting users based on their search queries and browsing behavior.',
    options: [
      { name: 'Search Ads', icon: GoogleIcon, grayIcon: GoogleIconGray, image: GoogleSearch },
      { name: 'Display Ads', icon: GoogleIcon, grayIcon: GoogleIconGray, image: GoogleDisplay },
      { name: 'Demand Gen', icon: GoogleIcon, grayIcon: GoogleIconGray, image: DemandGen },
      { name: 'Performance Max', icon: GoogleIcon, grayIcon: GoogleIconGray, image: PerformanceMax },
    ],
  },
  { name: '', icon: ElipseDot },
  {
    name: 'Meta',
    icon: MetaLogo,
    text: 'Meta Ads are targeted advertising tools on platforms like Facebook and Instagram, designed to help businesses reach their ideal audience. Supported formats include image ads, video ads, carousel ads, and slideshow ads, all offering customizable options to drive engagement and conversions effectively.',
    options: [
      { name: 'Single Image', icon: MetaIcon, grayIcon: MetaIconGray, image: MetaSingle },
      { name: 'Carousel', icon: MetaIcon, grayIcon: MetaIconGray, image: MetaCarousel },
      { name: 'Instant Experience', icon: MetaIcon, grayIcon: MetaIconGray, image: InstantExperience },
    ],
  },
  { name: '', icon: ElipseDot },
  {
    name: 'TikTok',
    icon: TikTokLogo,
    text: 'TikTok Ads are dynamic advertising solutions that allow brands to connect with the platform’s vibrant community. Supported formats include In-Feed Ads, TopView Ads, Branded Hashtag Challenges, and Branded Effects, enabling businesses to create engaging content that captures attention and drives interaction.',
    image: TikTok,
  },
  { name: '', icon: ElipseDot },
  {
    name: 'Snapchat',
    icon: SnapchatLogo,
    text: `Snapchat Ads are interactive advertising solutions designed to engage the platform's youthful audience. Supported formats include Snap Ads, Story Ads, Collection Ads, and AR Lenses, allowing brands to create immersive experiences that drive engagement and brand awareness.`,
    options: [
      { name: 'Single Image', icon: SnapchatIcon, grayIcon: SnapchatIconGray, image: SnapchatSingle },
      { name: 'Story', icon: SnapchatIcon, grayIcon: SnapchatIconGray, image: SnapchatStory },
      { name: 'Collection', icon: SnapchatIcon, grayIcon: SnapchatIconGray, image: SnapchatCollection },
    ],
  },
  { name: '', icon: ElipseDot },
  {
    name: 'LinkedIn',
    icon: LinkedInLogo,
    text: 'LinkedIn Ads are targeted advertising solutions designed for professionals and businesses on the platform. Supported formats include Sponsored Content, Message Ads, Dynamic Ads, and Text Ads, enabling brands to connect with decision-makers and drive engagement in a professional environment.',
    options: [
      { name: 'Single Image', icon: LinkedInIcon, grayIcon: LinkedInIconGray, image: LinkedinSingle },
      { name: 'Carousel', icon: LinkedInIcon, grayIcon: LinkedInIconGray, image: LinkedinCarousel },
    ],
  },
]
