import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Box, Image, Text, VStack, Button } from '@chakra-ui/react'
import Illustration_404 from '../assets/images/errors/Illustration_404.svg'

const NotFoundPage = () => {
  const navigate = useNavigate()
  const goToHome = () => {
    navigate('/') // Navigate to homepage
  }

  return (
    <VStack alignItems='center' spacing={10} m={10} justifyContent='center' minH='40vh'>
      <Image src={Illustration_404} alt='404 error' mx='auto' />
      <Text textAlign={'center'}>
        <Box>Oops! The page you are looking for might have been removed, had its name</Box>
        <Box>changed, or is temporarily unavailable.</Box>
      </Text>
      <Button onClick={goToHome} bg={'#7845DB'}>
        Take me home
      </Button>
    </VStack>
  )
}

export default NotFoundPage
