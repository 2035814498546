import React from 'react'
import { Box, Image, useBreakpointValue } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import LogoHorizontal from '../../assets/images/logo_horizontal.svg'
import Mark from '../../assets/images/Mark.svg'

export default function Logo(props) {
  const logoSrc = useBreakpointValue({ base: Mark, lg: LogoHorizontal })

  return (
    <Box {...props}>
      <Link to='/'>
        <Image src={logoSrc} alt='Logo' />
      </Link>
    </Box>
  )
}
