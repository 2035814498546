import React from 'react';
import '@fontsource/inter';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import customtheme from './components/theme/theme';
import NotFoundPage from './pages/NotFoundPage';
import Hero from './components/sections/Hero';

function App() {
  return (
    <Router basename="/">
      <ChakraProvider theme={customtheme}>
          <Routes>
            <Route path="/" element={<Hero />} />
            <Route path="/products" element={<NotFoundPage />} />
            <Route path="/pricing" element={<NotFoundPage />} />
            <Route path="/solutions" element={<NotFoundPage />} />
            <Route path="/resources" element={<NotFoundPage />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
      
      </ChakraProvider>
    </Router>
  );
}
export default App;