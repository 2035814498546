import React from 'react'
import { Button, Text } from '@chakra-ui/react'

const FreeTrialButton = ({ isVisible }) => {
  if (!isVisible) return null

  return (
    <Button
      bgGradient='linear(to-r, #EC6CFF, #7B61FF)'
      color='white'
      fontWeight='bold'
      py={2}
      px={4}
      borderRadius='full'
      _hover={{ bgGradient: 'linear(to-r, #D572FF, #6C59FF)' }}
      _active={{ bgGradient: 'linear(to-r, #C766FF, #5F4FF7)' }}
    >
      <Text fontSize='sm' fontWeight='semi-bold' whiteSpace={{ base: 'nowrap', md: 'normal', lg: 'nowrap' }}>
        14 day free trial included
      </Text>
    </Button>
  )
}

export default FreeTrialButton
