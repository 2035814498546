import React from 'react'
import { Box, Text, Divider, VStack, List } from '@chakra-ui/react'
import FreeTrialButton from '../elements/FreeTrialButton'
import FeaturesList from '../elements/FeaturesList'
import GetStartedWithPlanButton from '../elements/GetStartedWithPlanButton'

const PriceWrapper = ({ plan, isMonthly, isOpenFeatures, onToggleFeatures }) => {
  const { planName, pricePerMonth, priceAnnualPerMonth, saveWithAnnual, features } = plan
  const price = isMonthly ? pricePerMonth : priceAnnualPerMonth
  const isPriceNumber = !isNaN(price)

  return (
    <VStack
      maxW={{ base: 'full', md: '30%' }}
      minW={{ base: 'full', md: '30%' }}
      borderWidth='1px'
      borderRadius='lg'
      bg='#FFFFFF'
      boxShadow='lg'
      p={6}
      m='2'
      align='start'
      spacing='10px'
    >
      <Text fontWeight='bold' fontSize='lg' color='#4D5761'>
        {planName}
      </Text>
      <Text fontSize={{ base: '3xl', md: '4xl' }} fontWeight='bold' color='#4D5761' maxW={'185px'} align={'left'}>
        {isPriceNumber ? '$' : ''}
        {price}
        <Text as='span' fontSize='md' fontWeight='normal' color='#9DA4AE'>
          {isPriceNumber ? '/month' : ''}
        </Text>
      </Text>
      {saveWithAnnual && (
        <Text fontSize={{ base: 'sm' }} fontWeight={500} color='#2E90FA'>
          Save ${saveWithAnnual} with annual subscription
        </Text>
      )}
      {isPriceNumber && (
        <Box width='full' align='left' my={3}>
          <FreeTrialButton isVisible={isPriceNumber} />
        </Box>
      )}

      <Divider color={'#E1E4ED'} borderWidth='1px' my={3} />
      <Box pt={4} width='full'>
        <List w={'full'} fontSize={'sm'} spacing={4} textAlign='start' mb={5}>
          {features.map((feature, index) => (
            <FeaturesList
              key={index}
              feature={feature}
              index={index}
              isOpenFeatures={isOpenFeatures}
              onToggleFeatures={onToggleFeatures}
            />
          ))}
        </List>
      </Box>
      <Box mt='auto' width='full'>
        <GetStartedWithPlanButton planName={planName} isMonthly={isMonthly} w='full' />
      </Box>
    </VStack>
  )
}

export default PriceWrapper
