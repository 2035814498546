import React from 'react'
import { VStack, Text, Grid, GridItem } from '@chakra-ui/react'
import {
  Hero3Image1,
  Hero3Image2,
  Hero3Image3,
  Hero3Image4,
  Hero3Image5,
  Hero3bg1,
  Hero3bg2,
  Hero3bg3,
  Hero3bg4,
  Hero3bg5,
} from './index'
import { FiCpu, FiFeather, FiClipboard } from 'react-icons/fi'
import { IoMdCheckmarkCircleOutline } from 'react-icons/io'
import FeatureCard from '../elements/FeatureCard'

export default function Hero3() {
  return (
    <VStack m={{ base: 5, md: 0 }} mx={{ md: 10, lg: 0 }} w={{ base: 'auto' }} id='features'>
      <VStack align='center' w='full' my={{ base: 5, md: 10 }}>
        <VStack align={'flex-start'} textAlign={'center'} id='top-feature' mb={{ base: 5, md: 10 }}>
          <Text fontSize={{ base: '4xl', md: '6xl' }} fontWeight='600' color='#422678'>
            Features that you need
          </Text>
        </VStack>

        <Grid
          templateColumns={{ base: '1fr', lg: 'repeat(2, 1fr)' }}
          gap={{ base: 6, md: 8, lg: 5, xl: 8 }}
          mx={{ base: 0, md: 10, lg: 10 }}
          w={{ base: 'auto', md: 'full', lg: '90%', xl: 'full', '2xl': '1280px' }}
        >
          <GridItem>
            <FeatureCard
              buttonText='AI Content Generator'
              buttonIcon={<FiCpu />}
              title='AI-Boosted Ad Headlines and Descriptions'
              subtitle='AI Copy Generator to make your headlines and descriptions faster, better and attractive with OpenAI integration on all formats of ad channels.'
              imageSrc={Hero3Image1}
              imageAlt='AI Content Generator'
              bgImage={Hero3bg1}
            />
          </GridItem>
          <GridItem>
            <FeatureCard
              buttonText='Approval Process'
              buttonIcon={<IoMdCheckmarkCircleOutline />}
              title='Streamlined Client feedback and approval'
              subtitle='Seamless approval process with clients as they can view, comment and collaborate. No more loss of communication and endless emails.'
              imageSrc={Hero3Image2}
              imageAlt='Media Plan'
              bgImage={Hero3bg2}
              mb={{ base: 5, md: 10 }}
            />
          </GridItem>
          <GridItem mt={{ base: 0, lg: -24 }}>
            <FeatureCard
              buttonText='Integrations'
              buttonIcon={<FiCpu />}
              title='Effortlessly blend essential assets through integrations'
              subtitle='Integrate your ads manager on google, meta and tiktok and import the assets directly into the preview modules and into the assets folder to reuse again on campaign previews.'
              imageSrc={Hero3Image3}
              imageAlt='Approval Process'
              bgImage={Hero3bg3}
              imagePosition='right'
            />
          </GridItem>
          <GridItem alignSelf='end'>
            <FeatureCard
              buttonText='Agency Branding'
              buttonIcon={<FiFeather />}
              title='Make it yours'
              subtitle='Brand the Client Portal links with your own logo, name, colors and favicon.'
              imageSrc={Hero3Image4}
              imageAlt='Approval Process'
              bgImage={Hero3bg4}
              imagePosition='right'
              borderRadius='0 0 15px 0'
              mt={{ lg: 10, xl: -1 }}
            />
          </GridItem>
        </Grid>
        <FeatureCard
          buttonText='Media Plans'
          buttonIcon={<FiClipboard />}
          title='Say Goodbye to Excel: Effortless Media Plan Creation'
          subtitle='No More Excel Sheets! Hassle free media plan creation and instantly share it with your clients for approvals and comments.'
          imageSrc={Hero3Image5}
          imageAlt='Approval Process'
          bgImage={Hero3bg5}
          imagePosition='right'
          wideCard={true}
          p={{ base: '0px 15px 5px 0px', md: '30px 20px 0px 0px' }}
          id='media-plans'
        />
      </VStack>
    </VStack>
  )
}
