import React from 'react'
import { Link, Flex, Spacer, Text, Image } from '@chakra-ui/react'
import { WhiteLogo } from './index'

const Footer = () => {
  return (
    <Flex
      direction={{ base: 'column', md: 'row' }}
      align='center'
      justify={'center'}
      m='0 20'
      w='100%'
      p={20}
      bg={'brand.700'}
      color='white'
    >
      <Flex
        maxW={'1280px'}
        w={{ base: '100%', xl: '1280px' }}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent='space-between'
        alignItems='center'
      >
        <Flex align='center' color='white' w='200px'>
          <Image src={WhiteLogo} alt='Logo' />
        </Flex>

        <Spacer />

        <Flex direction={{ base: 'column', md: 'row' }} alignItems='center'>
          <Text fontSize='sm' color='#D5C5F4' textAlign={'center'}>
            Copyright © 2024 WhatCampaign{' '}
          </Text>
          <Text fontSize='sm' m='1' color='#D5C5F4' textAlign={'center'}>
            |
          </Text>
          <Text fontSize='sm' color='#D5C5F4' textAlign={'center'}>
            All Rights Reserved{' '}
          </Text>
          <Text fontSize='sm' m='1' color='#D5C5F4' textAlign={'center'}>
            |
          </Text>
          <Link href={'mailto:sales@whatcampaign.com'} fontSize='sm' textAlign={'center'}>
            Contact Us
          </Link>
          <Text fontSize='sm' m='1' color='#D5C5F4' textAlign={'center'}>
            |
          </Text>
          <Link href='https://whatcampaign.com/terms-of-service' fontSize='sm' textAlign={'center'}>
            Terms and Conditions
          </Link>
          <Text fontSize='sm' m='1' color='#D5C5F4' textAlign={'center'}>
            |
          </Text>
          <Link href='https://whatcampaign.com/privacy-policy' fontSize='sm' textAlign={'center'}>
            Privacy Policy
          </Link>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Footer
