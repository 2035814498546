import { tableAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(tableAnatomy.keys)

const baseStyle = definePartsStyle({
  th: {
    color: 'gray.600',
    fontWeight: 400,
    fontSize: 'xs',
    textTransform: 'capitalize'
  },
  td: {
    fontWeight: '400',
    fontSize: 'sm'
  },
})

export const tableTheme = defineMultiStyleConfig({ baseStyle })
