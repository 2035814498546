import React from 'react'

import Hero1 from './Hero1'
import Hero2 from './Hero2'
import Hero3 from './Hero3'
import Hero5 from './Hero5'
import ThreeTierPricing from './ThreeTierPricing'
import LandingLayout from '../layouts/LandingLayout'
import { VStack } from '@chakra-ui/react'

const Hero = () => {
  return (
    <LandingLayout>
      <VStack spacing='0px' m={{ base: 0, md: 10, lg: 0 }} w='full' maxWidth='100%' overflow='hidden'>
        <Hero1 />
        <VStack spacing='0' maxW='1280px'>
          <Hero2 />
          <Hero3 />
          <Hero5 />
        </VStack>
        <ThreeTierPricing />
      </VStack>
    </LandingLayout>
  )
}

export default Hero
