import { extendTheme } from "@chakra-ui/react";
import { inputTheme } from './components/Input'
import { buttonTheme } from "./components/Button";
import { menuTheme } from "./components/Menu";
import { cardTheme } from "./components/Card";
import { selectTheme } from "./components/Select";
import { tableTheme } from "./components/Table";
import '@fontsource/inter';

const config = {
  initialColorMode: "light",
  useSystemColorMode: false,
}


const customtheme = extendTheme({

   fonts: {
    heading: "Inter, sans-serif",
    body: "Inter, sans-serif",
  },
    fontWeights: {
    normal: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
    black: 900,
  },
  config,
  fontSizes: {
    '2xs': '0.625rem',

    
  },
  letterSpacings:{
    wider: "5px",
  },
  colors: {
    brandPrimary: '#7845DB',
    brand: {
      50: '#0f121a',
      100: '#F2ECFB',
      200: '#EBE3FA',
      300: '#151b26',
      400: '#171e2a',
      500: '#7845DB',
      600: '#6037AF',
      700: '#5A34A4',
      800: '#313a4e',
      850: '#36345C',
      900: '#0E0B3D',
    },
    header_menu: "#7B9AA7",
  },
  components: {
    Button: buttonTheme,
    Table: tableTheme,
    FormLabel: {
      baseStyle: {
        fontWeight: 500,
      },
    },
    Input: inputTheme,
    Menu: menuTheme,
    Card: cardTheme,
    Select: selectTheme
  }
});

export default customtheme;