import React from 'react'
import { HStack, Text, VStack, ListItem, ListIcon, Icon, Collapse } from '@chakra-ui/react'
import { FaCheckCircle } from 'react-icons/fa'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'

const FeaturesList = ({ feature, index, isOpenFeatures, onToggleFeatures }) => {
  const toggleFeatures = (e) => {
    e.preventDefault()
    e.stopPropagation()

    if (feature.options) {
      onToggleFeatures()
    }
  }

  return (
    <ListItem key={index} display={'flex'} justifyContent={'space-between'}>
      <VStack w={'full'} display={'flex'}>
        <HStack
          w={'full'}
          display={'flex'}
          justifyContent={'space-between'}
          onClick={(e) => toggleFeatures(e)}
          cursor={feature.options ? 'pointer' : 'default'}
        >
          <Text display={'flex'} fontWeight={'semibold'} color={'gray.600'} alignItems={'center'}>
            {feature.name}
            {feature.options && <Icon as={isOpenFeatures ? FiChevronUp : FiChevronDown} ml={1} />}
          </Text>
          <ListIcon as={FaCheckCircle} color='brand.500' />
        </HStack>

        {feature.options && (
          <Collapse style={{ width: '100%' }} in={isOpenFeatures}>
            <VStack my={1} alignItems={'flex-start'} w={'100%'}>
              {feature.options?.map((item, itemIndex) => (
                <Text as={'div'} w={'full'} fontSize={'sm'} color={'gray.600'} mt={2} key={itemIndex}>
                  {item}
                </Text>
              ))}
            </VStack>
          </Collapse>
        )}
      </VStack>
    </ListItem>
  )
}

export default FeaturesList
