import React from 'react'
import { Button } from '@chakra-ui/react'
import { FaArrowRight } from 'react-icons/fa'

const GetStartedWithPlanButton = ({ planName, isMonthly, ...props }) => {
  const handleClick = () => {
    window.gtag_report_conversion();

    const queryParams = new URLSearchParams({
      plan: planName.toLowerCase(),
      monthly: isMonthly ? 'true' : 'false',
    }).toString()

    if (planName === 'Enterprise') {
      window.location.href = 'mailto:sales@whatcampaign.com'
    } else {
      window.location.href = `https://app.whatcampaign.com/register?${queryParams}`
    }
  }

  return (
    <Button onClick={handleClick} rightIcon={<FaArrowRight size={14} />} {...props}>
      {planName === 'Enterprise' ? 'Talk to us' : 'Get Started'}
    </Button>
  )
}

export default GetStartedWithPlanButton
