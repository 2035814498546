import { Button, Image, Text } from '@chakra-ui/react'

const CustomAdButton = ({
  name,
  icon,
  grayIcon,
  isSelected,
  onClick,
  selectedColor = '#7845DB',
  unselectedColor = '#6C727E',
  hideTitleOnSmallScreens = false,
}) => {
  return (
    <Button
      onClick={onClick}
      variant='unstyled'
      display='flex'
      flexDirection='row'
      alignItems='center'
      justifyContent='center'
      bg={isSelected ? 'white' : 'transparent'}
      borderRadius='full'
      boxShadow={isSelected ? '0px 4px 12px rgba(147, 106, 226, 0.25)' : 'none'}
      py={2}
      px={4}
      transition='all 0.3s ease'
      _hover={{
        bg: 'white',
        boxShadow: '0px 4px 12px rgba(147, 106, 226, 0.25)',
      }}
      minWidth='auto'
    >
      <Image src={isSelected ? icon : grayIcon || icon} alt={`${name} Icon`} boxSize='20px' />
      <Text
        fontSize='xs'
        fontWeight='medium'
        color={isSelected ? selectedColor : unselectedColor}
        display={hideTitleOnSmallScreens ? { base: 'none', md: 'inline' } : 'inline'}
        ml={2}
      >
        {name}
      </Text>
    </Button>
  )
}

export default CustomAdButton
