import { inputAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys)

const baseStyle = definePartsStyle({
  field: {
    padding: 4,
    fontSize: 'sm',
    height: 'auto',
  },
  addon: {
    padding: 4,
    fontSize: 'sm',
    height: 'auto',
  }
})

export const inputTheme = defineMultiStyleConfig({
  baseStyle
})