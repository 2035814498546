// src/data/pricingData.js

const pricingPlans = [
  {
    planName: 'Starter',
    pricePerMonth: '29.99',
    priceAnnualPerMonth: '19.99',
    saveWithAnnual: '120',
    features: [
      {
        name: 'All features included',
        options: [
          'Ad Creation & Multi-Platform Preview',
          'Comprehensive Media Planning',
          'Client Collaboration Tools',
          'Branded Content Sharing',
          'AI-powered Suggestions',
          'Integrated Analytics & Reporting',
          'Integrations',
          'Excel-Free Operations',
          'Team Management',
        ],
      },
      { name: '2 User seats' },
      { name: '2 Workspaces' },
      { name: '500 MB memory' },
    ],
  },
  {
    planName: 'Growth',
    pricePerMonth: '59.99',
    priceAnnualPerMonth: '49.99',
    saveWithAnnual: '120',
    features: [
      {
        name: 'All features included',
        options: [
          'Ad Creation & Multi-Platform Preview',
          'Comprehensive Media Planning',
          'Client Collaboration Tools',
          'Branded Content Sharing',
          'AI-powered Suggestions',
          'Integrated Analytics & Reporting',
          'Integrations',
          'Excel-Free Operations',
          'Team Management',
        ],
      },
      { name: '5 User seats' },
      { name: '5 Workspaces' },
      { name: '1 GB memory' },
    ],
  },
  {
    planName: 'Enterprise',
    pricePerMonth: 'Custom pricing',
    priceAnnualPerMonth: 'Custom pricing',
    features: [
      {
        name: 'All features included',
        options: [
          'Ad Creation & Multi-Platform Preview',
          'Comprehensive Media Planning',
          'Client Collaboration Tools',
          'Branded Content Sharing',
          'AI-powered Suggestions',
          'Integrated Analytics & Reporting',
          'Integrations',
          'Excel-Free Operations',
          'Team Management',
        ],
      },
      { name: 'Custom User seats' },
      { name: 'Custom Workspaces' },
      { name: '1 GB memory' },
      { name: 'Dedicated support' },
    ],
  },
]

export default pricingPlans
