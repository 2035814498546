import React from 'react'
import {
  Button,
  Heading,
  Text,
  Image,
  VStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from '@chakra-ui/react'
import { ClientPortal } from './index'

export default function Hero5() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const iframeUrl =
    'https://app.whatcampaign.com/public/universal-agency/folders/YjBjZDM2YjEtMDU2Yy00YTYxLTkwN2UtMmVjYzM1Nzg0ZWM2/campaigns/1d1a87f0-1a0e-4d0e-ad49-ef15fcabf9e7'

  return (
    <VStack m={{ base: 5, md: 20 }} spacing='10'>
      <VStack maxW={{ base: '100%', md: '70%' }} spacing='8'>
        <Heading as='h2' fontSize={{ base: '4xl', md: '6xl' }} color='#422678' opacity='0.87' textAlign='center'>
          Client Portal
        </Heading>

        <Text fontSize={{ base: 'sm', md: 'md' }} color='#6C727E' opacity='0.8' textAlign='center' maxW='654px'>
          Quickly share media plan, previews on public link or private links for approval, with all campaign views. With
          option to select which campaign to show to your clients.
        </Text>

        <Button colorScheme='brand' borderRadius='8px' size='md' onClick={onOpen}>
          Try now
        </Button>
      </VStack>
      <VStack minW={{ base: '100%', md: '95%' }}>
        <Image src={ClientPortal} alt='Client Portal ' objectFit='contain' />
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size='xl'>
        <ModalOverlay bg='blackAlpha.300' backdropFilter='blur(10px)' />
        <ModalContent width='80%' height='80%' maxWidth='unset' maxHeight='unset'>
          <ModalHeader>Client Portal</ModalHeader>
          <ModalCloseButton />
          <ModalBody p={0}>
            <iframe
              src={iframeUrl}
              width='100%'
              height='100%'
              title='Client Portal'
              allow='fullscreen; autoplay; clipboard-write; encrypted-media; picture-in-picture'
              loading='lazy'
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  )
}
