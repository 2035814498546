import React from 'react'
import { Link as ScrollLink } from 'react-scroll'
import { Button, Heading, Image, VStack, Text } from '@chakra-ui/react'
import { CampaignList, HeroBg } from './index'

export default function Hero1() {
  return (
    <VStack mx={{ base: 5, md: 20 }} mt={{ base: 5, md: 10 }} spacing={{ base: 10, md: 20 }} bgImage={HeroBg} w='full'>
      <VStack maxW={{ base: '100%', md: '80%', lg: '1280px' }} spacing='5'>
        <Text size='xl' fontWeight='bold' color='brand.500' lineHeight='1.2' textAlign='center'>
          WHATCAMPAIGN
        </Text>
        <Heading as='h2' fontSize={{ base: '4xl', md: '6xl' }} color='#422678' textAlign='center'>
          Create Previews, Media Plans <br />
          and Approve Ads <span style={{ color: '#7845DB' }}>faster</span>
        </Heading>
        <Text fontSize={{ base: 'sm', md: 'lg' }} color='#36345C' opacity='0.8' textAlign='center' maxW={'900px'}>
          NO MORE lengthy email threads, never ending presentations, screenshots and excel sheets for your campaign
          approval process with your clients or management.
        </Text>

        <Button colorScheme='brand' borderRadius='8px' size='md'>
          <ScrollLink to={'pricing-section'} smooth={true} duration={500}>
            {'Get started'}
          </ScrollLink>
        </Button>
      </VStack>

      <VStack minW={{ base: '100%', md: '95%' }}>
        <Image src={CampaignList} alt='campaigns dashboard' objectFit='contain' />
      </VStack>
    </VStack>
  )
}
