import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const brandPrimary = defineStyle({
  background: 'brand.500',
  color: 'white',
  h: 'auto',
})

const outline = defineStyle({
  borderColor: 'gray.300',
  h: 'auto',

  _hover: {
    color: 'white',
    bg: 'brand.500',
    borderColor: 'brand.500'
  }
})

const ghost = defineStyle({
  _hover: {

  }
})

export const buttonTheme = defineStyleConfig({
  variants: { brandPrimary, outline, ghost },
  baseStyle: {
    fontWeight: 400,
    padding: 4,
    width: 'auto',
    _hover: {
      _disabled: {
        background: 'gray.600',
        color: 'white',
      }
    }
  },
  defaultProps: {
    variant: 'brandPrimary',
    colorScheme: 'brand',
  },
})
