import React from 'react'
import { VStack, Text } from '@chakra-ui/react'
import { Flex, Heading, useDisclosure, Switch } from '@chakra-ui/react'
import PriceWrapper from '../elements/PriceWrapper'
import pricingPlans from '../content/pricingData'
import { PricingBg } from './index'

const ThreeTierPricing = () => {
  const { isOpen: isMonthly, onToggle } = useDisclosure({ defaultIsOpen: true })
  const { isOpen: isOpenFeatures, onToggle: onToggleFeatures } = useDisclosure()

  return (
    <VStack bg='brand.500' minW='full' maxW='full'>
      <VStack
        p={{ base: 10, md: 5, lg: 10 }}
        spacing='10'
        bg='#F4EBFF'
        bgImage={PricingBg}
        bgRepeat='no-repeat'
        bgPosition='center'
        bgPos={{ base: 'bottom', md: 'right' }}
        textAlign='center'
        width='full'
        id='pricing-section'
      >
        <VStack maxW={{ base: '100%', md: '70%' }} spacing='10'>
          <Heading as='h2' fontSize={{ base: '4xl', md: '5xl' }} lineHeight={1.2} color='#422678'>
            Friendly pricing plans
          </Heading>
          <Text fontSize={{ base: 'md', md: 'lg' }} color='#4D5761'>
            Choose a plan and start your 14-day free trial
          </Text>
          <Flex justify='center' align='center'>
            <Text mr={2} color='#6C727E'>
              Monthly
            </Text>
            <Switch
              isChecked={!isMonthly}
              onChange={onToggle}
              colorScheme='purple'
              sx={{
                '& .chakra-switch__track': {
                  bg: 'purple.500',
                },
                '& .chakra-switch__thumb': {
                  bg: 'white',
                },
              }}
            />
            <Text ml={2} color='#6C727E'>
              Annually
            </Text>
          </Flex>
        </VStack>
        <Flex
          direction={['column', 'row']}
          align='stretch'
          justify='center'
          wrap='wrap'
          spacing={8}
          maxW='1280px'
          w='100%'
        >
          {pricingPlans.map((plan, index) => (
            <PriceWrapper
              key={index}
              plan={plan}
              isMonthly={isMonthly}
              isOpenFeatures={isOpenFeatures}
              onToggleFeatures={onToggleFeatures}
            />
          ))}
        </Flex>
      </VStack>
    </VStack>
  )
}

export default ThreeTierPricing
