import React, { useState, useEffect } from 'react'
import { Box, VStack, HStack, Image, Heading, Text, Stack, Flex } from '@chakra-ui/react'
import { motion, AnimatePresence } from 'framer-motion'
import CustomAdButton from '../elements/CustomAdButton'
import { buttons } from '../content/adPreviewData'
import { Hero2Bg } from './index.js'

export default function Hero2() {
  const [selectedItem, setSelectedItem] = useState(buttons[0])
  const [currentAd, setCurrentAd] = useState(buttons[0].options[0])
  const [currentIndex, setCurrentIndex] = useState(0)
  const [currentOptionIndex, setCurrentOptionIndex] = useState(0)

  useEffect(() => {
    const interval = setInterval(() => {
      if (selectedItem.options && currentOptionIndex < selectedItem.options.length - 1) {
        const nextOptionIndex = currentOptionIndex + 1
        setCurrentOptionIndex(nextOptionIndex)
        setCurrentAd(selectedItem.options[nextOptionIndex])
      } else {
        let nextIndex = (currentIndex + 1) % buttons.length
        let nextItem = buttons[nextIndex]

        while (nextItem.name === '') {
          nextIndex = (nextIndex + 1) % buttons.length
          nextItem = buttons[nextIndex]
        }

        setCurrentIndex(nextIndex)
        setSelectedItem(nextItem)
        setCurrentOptionIndex(0)

        if (nextItem.options) {
          setCurrentAd(nextItem.options[0])
        } else if (nextItem.image) {
          setCurrentAd({ image: nextItem.image })
        }
      }
    }, 3000)

    return () => clearInterval(interval)
  }, [currentIndex, currentOptionIndex, selectedItem])

  const handleCategoryClick = (button) => {
    setSelectedItem(button)
    setCurrentIndex(buttons.findIndex((b) => b.name === button.name))
    setCurrentOptionIndex(0)

    if (button.options) {
      setCurrentAd(button.options[0])
    } else if (button.image) {
      setCurrentAd({ image: button.image })
    }
  }

  const handleOptionClick = (option) => {
    setCurrentAd(option)
    setCurrentOptionIndex(selectedItem.options.findIndex((o) => o.name === option.name))
  }

  return (
    <Stack
      direction={{ base: 'column', lg: 'row' }}
      justifyContent={'space-between'}
      m={{ base: 5, md: 0, lg: 10 }}
      mt={{ base: 8, md: 10, lg: 24 }}
      p={{ base: 5, md: 10 }}
      bg='white'
      w={{ md: '90%', xl: '95%', '2xl': '1280px' }}
      border='1px solid #F2ECFB'
      borderRadius={'16px'}
      bgImage={Hero2Bg}
      bgRepeat='no-repeat'
      bgSize='cover'
      id='campaigns'
    >
      <VStack spacing='5' align='left' alignSelf={'center'}>
        <Heading as='h2' fontSize='4xl' bg='#422678' lineHeight='1.2' bgClip='text' maxW='484px'>
          Create and preview campaigns effortlessly
        </Heading>
        <Text fontSize={{ base: 'sm', md: 'md' }} color='#6C727E'>
          Creating previews has never been this quicker. Visualize how your campaigns will look on multiple advertising
          platforms with the latest ad formats.
        </Text>
        <HStack spacing={1} justify='left' flexWrap='wrap'>
          {buttons.map((button, index) =>
            button.name === '' ? (
              <Image src={button.icon} alt={`${button.name} Icon`} boxSize='5px' key={index} />
            ) : (
              <CustomAdButton
                key={index}
                name={button.name}
                icon={button.icon}
                isSelected={selectedItem.name === button.name}
                onClick={() => handleCategoryClick(button)}
                selectedColor='#1F2A37'
                hideTitleOnSmallScreens={true}
              />
            )
          )}
        </HStack>
        {selectedItem.text && (
          <Text fontSize='md' color='#6C727E'>
            {selectedItem.text}
          </Text>
        )}

        {selectedItem.options && (
          <Flex flexWrap='wrap' gap={2} alignItems='flex-start'>
            {selectedItem.options.map((option, index) => (
              <CustomAdButton
                key={index}
                name={option.name}
                icon={option.icon}
                grayIcon={option.grayIcon}
                isSelected={currentAd?.name === option.name}
                onClick={() => handleOptionClick(option)}
                hideTitleOnSmallScreens={false}
              />
            ))}
          </Flex>
        )}
      </VStack>

      <HStack
        maxW={{ base: '100%', md: '70%' }}
        position='relative'
        alignSelf={'center'}
        h={{ base: '300px', md: '450px', lg: '500px' }}
      >
        <AnimatePresence exitBeforeEnter>
          <motion.div
            key={currentAd.image}
            initial={{ opacity: 0, y: -50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 0.5, ease: 'easeInOut' }}
          >
            {currentAd?.image && (
              <Box
                justifyContent='center'
                alignItems='center'
                alignSelf={'center'}
                width={{ base: '300px', md: '450px', lg: '500px' }}
                height={{ base: '300px', md: '450px', lg: '550px' }}
                display='flex'
                overflow='hidden'
                bgSize='contain'
                bgRepeat='no-repeat'
                bgPos='center'
                bgImage={currentAd.image}
              ></Box>
            )}
          </motion.div>
        </AnimatePresence>
      </HStack>
    </Stack>
  )
}
